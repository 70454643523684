import React, { useState } from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { Disqus } from 'gatsby-plugin-disqus'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Layout from "../layout";
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import "../css/projects.css"
import SEO from "../seo"


const shortcodes = {
  Link: props => <Link className='textLinks' {...props}>{props.children}</Link>,
  img: props => <p className='centerImage'><img id='#zoom' alt={props.alt} src={props.src} /></p>,
  a: props => <a className='textLinks' target='_blank' rel="noreferrer" href={props.href}>{props.children}</a>,
  ul: props => <ul {...props} style={{ listStyle: 'none'}}/>,
  li: props => <li><FontAwesomeIcon icon={faLongArrowAltRight}/>&nbsp;{props.children}</li>,
  p: props => <p {...props} />,
  blockquote: props => <div className='indent-quote'>{props.children.props.children}</div>
} // Provide common components here

// function hoverLinks(props) {
//   return (<a className='textLinks' href={props.url}>{props.text}</a>)
// }id="zoom-scrollOffset"

  
export default function PageTemplate({ data: { mdx }, pathContext }) {
  
  const { id, next, prev } = pathContext;
  let disqusConfig = {
    identifier: mdx.id,
    title: mdx.frontmatter.title,
  }
  
  function hook(color) {
    setIsDarkMode(color)
  }
  
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const shareurl = 'https://kiteretsu.tech' + mdx.frontmatter.url
  return (
    <Layout page="datapage" hook={hook}>
      <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.excerpt} />
      <div className='full_background'>
        <Container maxWidth="xl" className='data_header'>
          <Container maxWidth="md" style={{ paddingTop: '8rem', paddingBottom: '4rem', textAlign: 'center' }}>
            <Typography variant="body1" component="h5" style={{ margin: '1rem' }}>
              <Link to='/projects'><FontAwesomeIcon icon={faLongArrowAltLeft} />&nbsp;&nbsp;View All Projects</Link>
            </Typography>
            <Typography variant="h3" component="h3">
              <b>
                {mdx.frontmatter.title}
              </b>
            </Typography>
            <Typography variant="body1" component="h5" display="inline">
              {mdx.frontmatter.date}
            </Typography>
            <b>&nbsp;|&nbsp;</b>
            <Typography variant="body1" component="h5" display="inline">
              {mdx.timeToRead}min read
            </Typography>
            {/* <Typography variant="body1" component="h6">
              <div dangerouslySetInnerHTML={{ __html: mdx.frontmatter.excerpt }} />
            </Typography> */}
          </Container>
        </Container>
        <Container maxWidth="md" className='content_cont'>
          <Paper elevation={2}>
            <div className='data_cont'>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </MDXProvider>
              <div className='divider'></div>
              <div style={{marginBottom:'2rem'}}>
                <Typography variant="body1" component="h5">
                  <b>Share this:</b>
                </Typography>
                <Grid container direction="row"
                  justify="start" spacing={1}>
                  <Grid item> 
                    <EmailShareButton url={shareurl} className="button is-outlined is-rounded email" >
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                  </Grid>
                  
                  <Grid item>
                    <FacebookShareButton url={shareurl} className="button is-outlined is-rounded facebook" >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                  </Grid>
                  
                  <Grid item>
                    <TwitterShareButton url={shareurl} className="button is-outlined is-rounded twitter" title={mdx.frontmatter.title} via={'kiteretsu_'}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </Grid>
                  
                  <Grid item>
                    <LinkedinShareButton url={shareurl} className="button is-outlined is-rounded linkedin" title={mdx.frontmatter.title} >
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                  </Grid>
                  
                  <Grid item>
                    <RedditShareButton url={shareurl} className="button is-outlined is-rounded reddit" title={mdx.frontmatter.title} >
                      <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                  </Grid>
                  
                  <Grid item>
                    <TelegramShareButton url={shareurl} className="button is-outlined is-rounded telegram" title={mdx.frontmatter.title} >
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                  </Grid>
                  
                  <Grid item>
                    <WhatsappShareButton url={shareurl} className="button is-outlined is-rounded whatsapp" title={mdx.frontmatter.title} >
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </Grid>
                </Grid>
              </div>
            
            </div>
            <div className='nav_cont'>
              <Grid container justify='space-between' style={{padding:'2rem'}}>
                <Grid item>
                  {
                    prev
                      ?
                      <Link to={prev.frontmatter.url}>
                        <Typography variant="h6" component="h6" align='right'>
                          <FontAwesomeIcon icon={faLongArrowAltLeft} />&nbsp;&nbsp;Prev Post
                        </Typography>
                        <Typography variant="body2" component="p" align='right'>
                          {prev.frontmatter.title}
                        </Typography>
                      </Link>
                      :
                      <Link to='/projects'>
                        <Typography variant="h6" component="h6" align='left'>
                          <FontAwesomeIcon icon={faLongArrowAltLeft} />&nbsp;&nbsp;No Prev Post
                        </Typography>
                        <Typography variant="body2" component="p" align='left'>
                          Return to Projects
                        </Typography>
                      </Link>
                  }
                </Grid>
                <Grid item>
                  {
                    next
                      ?
                      <Link to={next.frontmatter.url}>
                        <Typography variant="h6" component="h6" align='left'>
                          Next Post&nbsp;&nbsp;<FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Typography>
                        <Typography variant="body2" component="p" align='left'>
                          {next.frontmatter.title}
                        </Typography>
                      </Link>
                      :
                      <Link to='/projects'>
                        <Typography variant="h6" component="h6" align='left'>
                          No Next Post&nbsp;&nbsp;<FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Typography>
                        <Typography variant="body2" component="p" align='left'>
                          Return to Projects
                        </Typography>
                      </Link>
                  }
                  
                </Grid>
              </Grid>
            </div>
          </Paper>
          
          <Paper className='comment_cont' elevation={2}>
            <Grid container>
              <Grid item xs={12}>
                {
                  isDarkMode ?
                    <Disqus config={disqusConfig} />
                  :
                    <Disqus config={disqusConfig} />
                }
              </Grid>
            </Grid>
          </Paper>
        </Container>
        
        
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        title
        date
        excerpt
        url
      }
    }
  }
`